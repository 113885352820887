const accuracies = {
    level_pretrain: {
        0: 0.00096,
    },
    level_0: {
        10010: 0.30836,
        150150: 0.6655
    },
    level_1: {
        10010: 0.00772,
        15015: 0.42322,
        20020: 0.46878,
        25025: 0.48816,
        30030: 0.50794,
        35035: 0.49674,
        40040: 0.49234,
        45045: 0.5095,
        50050: 0.49488,
        55055: 0.6262,
        60060: 0.62916,
        65065: 0.63512,
        70070: 0.6372,
        75075: 0.63558,
        80080: 0.63926,
        85085: 0.63742,
        90090: 0.64028,
        95095: 0.63736,
        100100: 0.63576,
        105105: 0.6596,
        110110: 0.66076,
        115115: 0.66194,
        120120: 0.6629,
        125125: 0.66414,
        130130: 0.66464,
        135135: 0.6651,
        140140: 0.66522,
        145145: 0.66604,
        150150: 0.6665
    },
    level_1_pruned_from_ep2: {
        10010: 0.27806,
        15015: 0.37536,
        20020: 0.39078,
        25025: 0.40042,
        30030: 0.41484,
        35035: 0.4321,
        40040: 0.41678,
        45045: 0.44212,
        50050: 0.43564,
        55055: 0.56674,
        60060: 0.57452,
        65065: 0.58,
        70070: 0.57956,
        75075: 0.58486,
        80080: 0.57906,
        85085: 0.58558,
        90090: 0.57824,
        95095: 0.585,
        100100: 0.58236,
        105105: 0.61844,
        110110: 0.61982,
        115115: 0.6222,
        120120: 0.62194,
        125125: 0.6232,
        130130: 0.62562,
        135135: 0.62594,
        140140: 0.62574,
        145145: 0.62644,
        150150: 0.62576
    }
}

export function getAccuracy(branch, epoch) {
    const it = epoch * 5005;
    return accuracies[branch][it];
}