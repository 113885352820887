import React from 'react';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

export function CustomSlider (props) {
    const { labelText, defaultValue, valueLabelDisplay, step, min, max, value, onChange } = props;
    return (
        <div style={{width: "100%"}}>
            <Typography id="discrete-slider" gutterBottom>
                {labelText}
            </Typography>
            <Slider
                defaultValue={defaultValue}
                valueLabelDisplay={valueLabelDisplay}
                step={step}
                min={min}
                max={max}
                onChange={onChange}
                value={value}
            />
        </div>);
}