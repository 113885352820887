import React, {useState} from 'react';
import {getSelect} from './MaterialUtil'
import {CustomSlider} from './CustomSlider'


export function ControlView(props) {
    const {neuron, setNeuron, layer, setLayer} = props;

    return (<div className="row" style={{width:300}}> 
        <div className="column" style={{ flex: "1 1 50%", padding:10}}>
        {getSelect("Layer", ["layer1_1_bn2", "layer2_1_bn2", "layer3_1_bn2", "layer4_1_bn2"], layer, (e, value) => {
            setLayer(e.target.value);
        })}
        </div>
        <div className="column" style={{ flex: "1 1 50%", padding:10 }}>
            <CustomSlider
            labelText="Neuron"
            defaultValue={0}
            valueLabelDisplay
            step={1} min={0} max={63} value={neuron} onChange={(e, value) => {
                setNeuron(value);
            }}
            />
        </div>
    </div>)
}