export function getImageLayoutData(
    margin, imWidth, numBranches, branchIndex, epoch, epochsBeforeSplit) {
        // side margin is half margin between epochs / branches
        let branchesHeight = numBranches * imWidth + (numBranches-1) * margin;
        let totalHeight = margin + branchesHeight;
        let marginX = margin/2, marginY = margin/2;
        let rootBranchWidth = epochsBeforeSplit * (imWidth + margin)
        let x, y, width, height;
        width = height = imWidth;
        if (branchIndex === 0) {
            x = marginX + epoch * (margin + imWidth);
            y = marginY + branchesHeight/2 - imWidth / 2;
        } else {
            x = marginX + rootBranchWidth + (epoch-epochsBeforeSplit) * (margin + imWidth);
            y = marginY + (branchIndex-1) * (margin + imWidth);
        }
        return {x:x, y:y, w:width, h:height};
    }