import React, {useState} from 'react';
import logo from './logo.svg';
import './App.css';
import {TreeView} from './Components/TreeView'
import {ControlView} from './Components/ControlView'

function App() {
  const [neuron, setNeuron] = useState(0);
  const [layer, setLayer] = useState("layer4_1_bn2");

  return (
    <div className="App">
      <div className="column" style={{position:"relative", left:0, top:0}}>
        <div style={{position:"fixed", left:0, top:0}}>
          <ControlView
            layer={layer}
            neuron={neuron}
            setLayer={setLayer}
            setNeuron={setNeuron}
          />
        </div>
        <div style={{position:"relative", left:0, top:100}}>
          <TreeView
          layer={layer}
          neuron={neuron}/>
        </div>
      </div>
    </div>
  );
}

export default App;
