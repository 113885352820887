import React from 'react';
import {getImageLayoutData} from './LayoutUtil';
import {getAccuracy} from '../accuracies';

function getImageResourceUrl(branch, epoch, layer, neuron) {
    return "/feature_visualizations_r18/" + branch + "/model_" + 
    (epoch+"").padStart(3, "0") + "/" + layer + "_"  + (neuron+"").padStart(3, "0") + ".jpg";
}

export function TreeView(props) {
    const {layer, neuron} = props;
    const imWidth = 100;
    const margin = 100;
    const fs = 12;

    const epochData = [];
    for (let i=0; i<=1; i++) {
        const {x, y, w, h} = getImageLayoutData(margin, imWidth, 3, 0, i, 2);
        epochData.push({branch:"level_pretrain", epoch:i, x:x, y:y, w:w, h:h});
    }
    for (let i=2; i<=30; i++) {
        {
            const {x, y, w, h} = getImageLayoutData(margin, imWidth, 3, 1, i, 2);
            epochData.push({branch:"level_0", epoch:i, x:x, y:y, w:w, h:h});
        } {
            const {x, y, w, h} = getImageLayoutData(margin, imWidth, 3, 2, i, 2);
            epochData.push({branch:"level_1", epoch:i, x:x, y:y, w:w, h:h});
        } {
            const {x, y, w, h} = getImageLayoutData(margin, imWidth, 3, 3, i, 2);
            epochData.push({branch:"level_1_pruned_from_ep2", epoch:i, x:x, y:y, w:w, h:h});
        }

    }
    const e1Data = epochData[1];
    const e2Data = epochData[2];
    const e2L1Data = epochData[3];
    const e2L1e2Data = epochData[4];
    const e30L0Data = epochData[86];
    const sP = [e1Data.x+imWidth, e1Data.y+imWidth/2];
    const sP0 = [e2Data.x, e2Data.y+imWidth/2];
    const sP1 = [e2L1Data.x, e2L1Data.y+imWidth/2];
    const sP1e2 = [e2L1e2Data.x, e2L1e2Data.y+imWidth/2];
    const sP0e30 = [e30L0Data.x+imWidth*1.2, e30L0Data.y+imWidth/2];
    const pathL0 = "M "+sP[0] +" "+ sP[1] + 
    "C "+ sP0[0]+" "+sP[1]+", "+ sP[0]+" "+sP0[1]+", "+sP0[0]+" "+sP0[1];
    const pathL1 = "M "+sP0e30[0] +" "+ sP0e30[1] + 
    " Q "+ (sP0e30[0]+imWidth/2)+" "+sP0e30[1]+", "
    + (sP0e30[0]+imWidth/2)+" "+(sP0e30[1]+imWidth/2)+
    ", T "+ (sP0e30[0])+" "+(sP0e30[1]+imWidth)+
    ", T "+ (sP1[0])+" "+(sP1[1]-imWidth)+
    ", Q "+ (sP1[0]-imWidth/3)+" "+(sP1[1]-imWidth)+", "
    + (sP1[0]-imWidth/3)+" "+(sP1[1]-imWidth/2)+
    ", T "+ sP1[0]+" "+sP1[1];

    const pathPretrainLine = "M "+(sP[0]-imWidth-margin) +" "+ sP[1]+" L " + sP[0] +" "+ sP[1];
    const pathL0line = "M "+sP0[0] +" "+ sP0[1]+" L " + +sP0e30[0] +" "+ sP0[1];
    const pathL1line = "M "+sP1[0] +" "+ sP1[1]+" L " + +sP0e30[0] +" "+ sP1[1];
    const pathL1e2line = "M "+sP1e2[0] +" "+ sP1e2[1]+" L " + sP0e30[0] +" "+ sP1e2[1];
    
    /*
    + (sP1[0]-imWidth)+" "+sP1[1]+", "
    +sP1[0]+" "+sP1[1];*/
    const pathL1e2 = "M "+sP[0] +" "+ sP[1] + 
    "C "+ sP1e2[0]+" "+sP[1]+", "+ sP[0]+" "+sP1e2[1]+", "+sP1e2[0]+" "+sP1e2[1];
    return (<div>
        <svg viewBox="1000 600" width={31*imWidth + 30*margin+200} height={800}>
        <path d={pathL0} stroke="gray" fill="transparent"/>
        <path d={pathL1} stroke="gray" fill="transparent"/>
        <path d={pathL1e2} stroke="gray" fill="transparent"/>

        <path d={pathPretrainLine} stroke="gray" fill="transparent"/>
        <path d={pathL0line} stroke="gray" fill="transparent"/>
        <path d={pathL1line} stroke="gray" fill="transparent"/>
        <path d={pathL1e2line} stroke="gray" fill="transparent"/>
            {epochData.map((data, i) => {
                const {branch,epoch, x,y,w,h} = data;
                const acc = getAccuracy(branch, epoch);
                return (<>
                <image x={x} y={y} width={w} height={h}
                href={getImageResourceUrl(branch, epoch, layer, neuron)}
                />
                {acc !== undefined ? <>
                <rect x={x+w} y={y+w-h*acc} width={w*0.2} height={h*acc} fill="black" />
                <text fontSize={fs} x={x+w} y={y+w+fs+3} width={w*0.2} height={h} >{(acc*100).toFixed(2)}%</text>
                </>
                 : <text fontSize={fs} x={x+w+3} y={y+w+fs+3} width={w*0.2} height={h} >?</text>}
                </>)
            })}
            <text x={margin/2} y={40+margin+imWidth} class="small">initialized</text>
            <text x={margin/2+2*(imWidth+margin)} y={40} class="small">no pruning</text>
            <text x={margin/2+2*(imWidth+margin)} y={40+margin+imWidth} class="small">prune by 70% (weight rewinding to ep.2 after 30 epochs)</text>
            <text x={margin/2+2*(imWidth+margin)} y={40+2*margin+2*imWidth} class="small">prune by 70% (right away after ep.2)</text>
            {epochData.map((data, i) => {
                const {branch,epoch, x,y,w,h} = data;
                if(branch === "level_pretrain" || branch === "level_0") {
                    return (
                        <text x={x+20} y={0+3*margin+3*imWidth} class="small">epoch {epoch}</text>
                        )
                }
            })}
        </svg>
    </div>)
}